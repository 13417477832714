import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout/Layout";

import styles from "./pages.module.scss";

const Error404 = (props) => {

    return (
        <Layout
            location={props.location}
            title={"Page not found"}
            type={"404"}
        >
            <div className={styles.body}>
                <h1>Page not found</h1>
                <h2>Crikey! It looks like this page doesn't exist</h2>
                <p>Not to worry, these things happen.</p>
                <p>You can <Link to="/">return to the homepage</Link>, or if you think there should be something here, please contact <a href="mailto:support@ozarin.com" target="_blank" rel="noreferrer">support@ozarin.com</a> with details.</p>
            </div>
        </Layout>
    )
}

export default Error404;